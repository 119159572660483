import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6ef23dd6 = () => interopDefault(import('..\\pages\\bridge\\index.vue' /* webpackChunkName: "pages_bridge_index" */))
const _683d0908 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _70eed5f5 = () => interopDefault(import('..\\pages\\enrollment\\eligibility.vue' /* webpackChunkName: "pages_enrollment_eligibility" */))
const _b18aea20 = () => interopDefault(import('..\\pages\\enrollment\\error.vue' /* webpackChunkName: "pages_enrollment_error" */))
const _b61e971e = () => interopDefault(import('..\\pages\\enrollment\\rejection.vue' /* webpackChunkName: "pages_enrollment_rejection" */))
const _0957a12a = () => interopDefault(import('..\\pages\\enrollment\\success.vue' /* webpackChunkName: "pages_enrollment_success" */))
const _6622bcd6 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _66068dd4 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _65ce2fd0 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _65b200ce = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _01d4dcd6 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _45d8d884 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bridge",
    component: _6ef23dd6,
    name: "bridge"
  }, {
    path: "/unlock",
    component: _683d0908,
    name: "unlock"
  }, {
    path: "/enrollment/eligibility",
    component: _70eed5f5,
    name: "enrollment-eligibility"
  }, {
    path: "/enrollment/error",
    component: _b18aea20,
    name: "enrollment-error"
  }, {
    path: "/enrollment/rejection",
    component: _b61e971e,
    name: "enrollment-rejection"
  }, {
    path: "/enrollment/success",
    component: _0957a12a,
    name: "enrollment-success"
  }, {
    path: "/errors/400",
    component: _6622bcd6,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _66068dd4,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _65ce2fd0,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _65b200ce,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _01d4dcd6,
    name: "errors-500"
  }, {
    path: "/",
    component: _45d8d884,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
